<template>

  <div class="appbg">
    <title class="title-background">{{title}}</title>
    <div class="center">
      <div class="code_bg">
        <img class="iconappm" width="560" height="560" alt="" :src="url"/>
        <div class="codetit">{{codetit}}</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Code",
  data() {
    return {
      title:'',
      url:'',
      codetit:''
    };
  },

  mounted() {
    console.log('z啧啧啧',this.$route.query.qr)
    if(this.$route.query.qr =='xidi'){
      this.url=require('../assets/download/xidixiaochengxu.png')
      this.codetit = "截图打开微信「扫一扫」"
    }else{
      this.url=require('../assets/download/kuaiyaxiaochengxu.png')
      this.codetit = "截图打开支付宝「扫一扫」"
    }
    const mq = window.matchMedia("(max-width: 768px)");
    if (mq.matches) {
      if(this.$route.query.qr =='xidi'){
        document.title = "喜滴司服";
        this.title = "喜滴司服";
      }else{
        document.title = "快呀";
        this.title = "快呀";
      }
      
    } else {
      if(this.$route.query.qr =='xidi'){
        document.title = "喜滴司服";
        this.title = "喜滴司服";
      }else{
        document.title = "快呀";
        this.title = "快呀";
      }
    }
  },

  methods: {


  },
};
</script>
<style lang="scss" scoped>

.appbg{
  width: 100%;
  height: 100%;
  background: url("../assets/download/code_bg.png");
  background-size: 100% 100%;
}
.center{
  width:100%;
  padding-top:90px
}
.code_bg{
  margin:0 46px;
  border-radius:16px;
  background:#fff;
  height:760px;
  position:relative;
}
.iconappm{
  position:absolute;
  left: 50%;  /* 将子 div 的左边移到父 div 的中间 */
  top:58px;
  transform: translateX(-50%);  /* 将子 div 向左移动自身宽度的50%以居中 */
}
.codetit{
  font-size:28px;
  color:#071144;
  text-align:center;
  position:absolute;
  bottom:60px;
  left: 50%;  /* 将子 div 的左边移到父 div 的中间 */
  transform: translateX(-50%);  /* 将子 div 向左移动自身宽度的50%以居中 */
}
</style>
